<template>
  <div>
    <CRow v-if="!this.notifroute">
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
          <CRow class="padding-row">
            <CCol xl="2">
              <CButton color="primary" class="btn-sm" v-on:click="markAllread()"><v-icon name="eye" class="v-mid"/> Mark All Read</CButton>
            </CCol>
            <CCol xl="8"></CCol>
            <CCol xl="2">
              <CRow class="float-right">
                <CCol tag="label" xl="8" class="col-form-label padding-0">
                  Items per page:
                </CCol>
                <CCol xl="4" class="padding-0">
                <CSelect
                    :value.sync="itemperpage"
                    :options="filterperpage"
                    size="sm"
                    class="mb-0"
                  />  
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && notifications.length < 1">Data Belum Ada</span>
            <div v-if="notifications.length > 0" small="true" class="v-mid-tab" style="text-align: center;">
              <div class="position-relative table-responsive">
                <table class="table table-fixed table-hover table-bordered">
                  <thead>
                    <tr>
                      <th style="vertical-align: middle; overflow: hidden;">
                        <div>#</div>
                      </th>
                      <th style="vertical-align: middle; overflow: hidden;">
                        <div>Message</div>
                      </th>
                      <th style="vertical-align: middle; overflow: hidden;">
                        <div>Time</div>
                      </th>
                      <th style="vertical-align: middle; overflow: hidden;">
                        <div></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="position-relative">
                    <tr v-for="(notif, i) in notifications" :key="'n'+i" :style="notif.is_read?'background-color: rgba(0, 0, 21, 0.05);':''">
                      <td>
                        <div class="c-avatar">
                          <img :src="notif.data.picture?notif.data.picture:(notif.data.type=='message')?'img/avatars/profile_img.svg':'img/avatars/dot-circle.svg'" class="c-avatar-img-h100" alt=""/>
                        </div>
                      </td>
                      <td> <span v-html="notif.data.message"></span> </td>
                      <td> {{formatTimenotif(notif.created_at)}} </td>
                      <td>
                        <CDropdown
                          color="secondary"
                          placement="bottom"
                        >
                          <template #toggler>
                            <CButton color="secondary" class="btn-sm">
                              <v-icon name="caret-down"/>
                            </CButton>
                          </template>
                          <CDropdownItem @click="viewData(notif.id)">
                            <v-icon name="eye" class="mr-1"/>View
                          </CDropdownItem>
                          <!-- <CDropdownItem @click="removeData(notif.id)">
                            <v-icon name="trash" class="mr-1 v-mid"/> Delete
                          </CDropdownItem> -->
                        </CDropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <CPagination
              v-if="this.notifications.length > 0"
              size="sm"
              :active-page.sync="currentpage"
              :pages="lastpage"
              :doubleArrows="false"
              align="end"/>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this data ?</span>
      <template #header>
        <h6 class="modal-title">Remove Data</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CCol xl="12" v-if="!isMLoading">
        <span>{{form.message}}</span>
      </CCol>
      <template #header>
        <CRow class="modal-title col-12" v-if="!isMLoading">
          <CCol class="padding-0" xl="10">{{ form.title }}</CCol>
          <CCol class="padding-0 text-right" xl="2">{{formatTimenotif(form.time)}}</CCol>
        </CRow>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'Notification',
  components: {
  },
  props: {
      notifroute: {
        type: Object,
        default () {
            return this.$route.params.notifroute?this.$route.params.notifroute:null
        }
      },
  },
  data () {
    return {
        isLoading: false, isMLoading: false,
        modalForm: false, modalDelete: false,
        tableconfig: {
          totalperpage: 15,
          pagenumber: 1,
          for: 'cc',
          _method: 'GET'
        },
        notifications: [],
        delId: '', frmId: '',
        form: {
          id: '',
          message: '',
          time: '',
          title: ''
        },
        filterperpage: [15, 25, 50, 100],
        itemperpage: 15, lastpage: 1, currentpage: 1
    }
  },
  watch: {
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.frmId = '', 
          this.form = { id: '', message: '', time: '', title: '' }
        }
      }
    },
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){ this.delId = '' }
      }
    },
    currentpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.pagenumber = this.currentpage
          this.reRender() 
        }
      }
    },
    itemperpage: {
      handler: function (val, oldVal) {
        if(val!=oldVal) { 
          this.tableconfig.totalperpage = this.itemperpage
          this.reRender() 
        }
      }
    },
  },
  created() {
    if(this.notifroute) {
      this.changeRoute(this.notifroute)
    } else {
      this.reRender()
    }
  },
  methods: {
    ...mapActions('notification', ['showallNTF', 'readallNTF', 'readNTF', 'showNTF']),
    reRender(){
      this.isLoading = true
      this.notifroute = null
      this.notifications = []
      this.showallNTF(this.tableconfig).then((response) => {
        this.notifications = response.data.length > 0 ? response.data : []
        this.currentpage = response.currentpage
        this.lastpage = response.lastpage
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    markAllread() {
      this.readallNTF().then((response) => { this.$router.go() }).catch((error) => {
        console.log('error ', error)
      })
    },
    viewData(uuid){
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    removeData(uuid){
      this.modalDelete = true
      this.delId = uuid
    },
    showData(uuid){
      this.isMLoading = true
      this.readNTF(uuid).then((response) => {
      }).catch((error) => {
        console.log('error ', error)
      })
      this.showNTF(uuid).then((response) => {
        this.form = {
          id: response.id,
          message: response.data.message,
          time: response.created_at,
          title: response.data.notification_for
        }
        this.isMLoading = false
      }).catch((error) => {
        this.isMLoading = false
      })
    },
    destroyData(uuid){
      // this.modalDelete = false
      // this.destroyNTF(uuid).then((response) => {
      //   this.reRender()
      // }).catch((error) => {
      //   console.log('error ', error)
      //   this.isLoading = false
      // })
    },
    changeRoute(val) {
      if(val.data.type=='message') {
        this.$router.push({name: 'Chat Group', params: { roomid: parseInt(val.data.room_id), roomtype: val.data.room_type }})
      } else {
        this.reRender()
      }
    },
    formatTimenotif(value) {
        if (!value) return '-'
        return moment(String(value)).format('DD/MM/YY hh:mm')
    },
  }
}
</script>